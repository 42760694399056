<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-12 text-left">
            <b-alert
              dismissible
              fade
              :variant="alertVariant"
              :show="dismissCountDown"
              @dismissed="dismissCountDown = 0"
            >
              {{ alertText }}
            </b-alert>

            <h2>{{ $t('users') }}</h2>

            <div
              class="form-group d-flex flex-column flex-md-row align-items-md-center"
            >
              <div class="users-search-box d-flex align-items-center">
                <label style="margin: 0 10px 0 0">{{
                  $t('search')
                }}</label>
                <b-form-input
                  id="search"
                  v-model="search"
                  type="text"
                  :placeholder="$t('searchUsers')"
                  :title="$t('searchUsers')"
                />
              </div>
              <div
                class="users-btn-container d-flex align-items-center pt-2 pt-md-0"
              >
                <span
                  class="btn btn-secondary ml-md-3"
                  @click="showUploadUsersModal()"
                  >+ {{ $t('addUsers') }}</span
                >
                <span
                  class="btn btn-secondary ml-2 ml-md-3"
                  @click="showFileStatusesPage()"
                >
                  {{ $t('fileStatuses') }}</span
                >
              </div>
            </div>
            <users-table
              :has-filter="false"
              :loading="loading"
              :items="userData"
              :columns="columns"
              :actions="actions"
              @edit="openEditUserModal"
              @building-access="openBuildingAccessModal"
              @remove-user="openRemoveUserModal"
            />
          </div>
          <div class="overflow-auto">
            <span
              v-if="users.keycloakUsersLength == 100 && search"
              class="btn btn-secondary ml-auto"
              @click="loadUsers"
            >
              {{ $t('loadMore') }}
            </span>
          </div>
          <b-modal id="modal-remove" hide-footer>
            <template v-slot:modal-title>
              <strong>{{ $t('removeUser') }}</strong>
            </template>
            <div class="d-block text-center">
              <p>{{ $t('deleteConfirmation') }}</p>
              <b-button
                variant="danger"
                class="mt-1"
                @click="$bvModal.hide('modal-remove')"
              >
                {{ $t('no') }}
              </b-button>
              <b-button
                class="mt-1"
                style="margin-left:20px"
                primary
                @click="removeBuildingUser"
              >
                {{ $t('yes') }}
              </b-button>
            </div>
          </b-modal>
        </div>
      </div>
    </section>
    <user-modal
      :user-id="
        selectedUser && action === 'edit' ? selectedUser.id : ''
      "
      :show="!!selectedUser && action === 'edit'"
      @done="selectedUser = null"
      @cancel="selectedUser = null"
    />
    <user-building-access-modal
      :user="
        selectedUser && action === 'access' ? selectedUser : null
      "
      :show="!!selectedUser && action === 'access'"
      @done="selectedUser = null"
      @cancel="selectedUser = null"
    />
    <upload-users-modal />
    <Alert />
  </div>
</template>

<script>
import moment from 'moment-timezone'
import OkkuApi from '@/services/OkkuApi'
import { mapActions, mapState } from 'vuex'
import UsersTable from '@/components/common/Table'
import UserBuildingAccessModal from '@/components/modals/UserBuildingAccessModal'
import UserModal from '@/components/modals/UserModal'
import UploadUsersModal from '@/components/modals/UploadUsersModal'
import Alert from '@/components/common/Alert'
import { encryptString } from '../../services/Helper'

const DEFAULT_COLUMNS = [
  {
    class: '',
    header: 'firstName',
    key: 'firstName'
  },
  {
    class: '',
    header: 'lastName',
    key: 'lastName'
  },
  {
    class: '',
    header: 'email',
    key: 'email'
  }
]

export default {
  name: 'UsersPage',
  components: {
    UsersTable,
    UserBuildingAccessModal,
    UserModal,
    UploadUsersModal,
    Alert
  },
  data() {
    return {
      columns: DEFAULT_COLUMNS,
      currentPage: 1,
      actions: [
        {
          name: 'edit',
          class: 'action link-edit text-primary',
          event: 'edit',
          icon: 'edit'
        },
        {
          name: 'buidlingAccess',
          class: 'action link-edit text-info',
          event: 'building-access',
          icon: 'building'
        },
        {
          name: 'remove',
          class: 'action link-edit text-danger',
          event: 'remove-user',
          icon: 'trash'
        }
      ],
      search: '',
      userData: [],
      selectedUser: null,
      action: null,
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: ''
    }
  },
  computed: {
    ...mapState('users', {
      users: 'orgUsers',
      loading: 'fetchingAllUsers'
    }),
    ...mapState('common', {
      isDashboardAdmin: '$isDashboardAdmin',
      isCustomerCoordinator: '$isCustomerCoordinator',
      isCustomerAdmin: '$isCustomerAdmin'
    })
  },
  watch: {
    search(val) {
      this.userData = []
      this.currentPage = 1
      if (val.length > 1) {
        this.loadUsers(this.currentPage)
      }
    }
  },
  created() {},
  methods: {
    ...mapActions('users', ['getOrgUsers', 'removeUser']),
    loadUsers() {
      clearTimeout(this.timeoutHandler)

      const delayedLoad = async () => {
        await this.getOrgUsers({
          search: this.search,
          page: this.currentPage
        })

        const { users } = this.users
        if (users && users.length) {
          this.currentPage += 1
          this.userData = this.userData.concat(users)
        }
      }

      this.timeoutHandler = setTimeout(() => {
        delayedLoad()
      }, 1000)
    },
    searchUsers() {
      return this.getOrgUsers({
        search: this.search
      })
    },
    updateAlert(variant, text) {
      this.dismissCountDown = 3
      this.alertVariant = variant
      this.alertText = text
    },
    async removeBuildingUser() {
      const encryptedEmail = encryptString(this.selectedUser.email)

      const fromDate = moment()
        .subtract(1, 'days')
        .toISOString()
      OkkuApi.getReservations(
        fromDate,
        encryptedEmail,
        undefined,
        undefined,
        '',
        'start_datetime',
        'ASC'
      ).then(reservations => {
        reservations.forEach(reservation => {
          OkkuApi.cancelReservation(reservation.hash)
        })
      })
      await this.removeUser(this.selectedUser.id)
      await this.getOrgUsers({
        search: this.search
      })
      this.userData = this.users.users

      this.$bvModal.hide('modal-remove')
    },
    async canEditUser(user) {
      try {
        const response = await OkkuApi.canEditUser(user.id)
        if (response === 'OK') {
          return true
        }
      } catch (e) {
        console.error(e)
        return false
      }
      return false
    },
    async openRemoveUserModal(user) {
      if (await this.canEditUser(user)) {
        this.action = 'remove'
        this.selectedUser = user
        this.$bvModal.show('modal-remove')
      } else {
        this.updateAlert('danger', this.$t('userCannotBeEdited'))
      }
    },
    async openEditUserModal(user) {
      if (await this.canEditUser(user)) {
        this.action = 'edit'
        this.selectedUser = user
      } else {
        this.updateAlert('danger', this.$t('userCannotBeEdited'))
      }
    },
    async openBuildingAccessModal(user) {
      if (await this.canEditUser(user)) {
        this.action = 'access'
        this.selectedUser = user
      } else {
        this.updateAlert('danger', this.$t('userCannotBeEdited'))
      }
    },
    async showUploadUsersModal() {
      if (
        this.isCustomerCoordinator ||
        this.isCustomerAdmin ||
        this.isDashboardAdmin
      ) {
        this.$bvModal.show('upload-users-modal')
      } else {
        this.updateAlert('danger', this.$t('notAuthorize'))
      }
    },
    showFileStatusesPage() {
      this.$router.push('/workplace/files/status')
    }
  }
}
</script>

<style scoped>
.users-search-box {
  width: 70%;
}
.users-btn-container {
  width: 30%;
}
.users-btn-container .btn {
  flex-grow: 1;
}
@media (max-width: 768px) {
  .users-search-box,
  .users-btn-container {
    width: 100%;
  }
  .users-btn-container .btn {
    flex-grow: 0;
  }
}
</style>
